<template>
  <div class="home-wrapper">
    <navbar></navbar>
    <div class="home-inner flex" :style="{ 'height': !!is_web ? 'calc(100% - 60px)' : 'calc(100% - 56px)' }">
      <!-- <sidebar></sidebar> -->
      <app-main></app-main>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Navbar, Sidebar, AppMain } from './components'

export default {
  name: 'layout',
  components: {
    Navbar,
    Sidebar,
    AppMain
  },
  computed: {
    ...mapState({
      is_web: state => state.app.isWebSide
    }),
  }
}
</script>

<style  lang="less" scoped>
  .home-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
  }
  // 主体区域
  .home-inner {
    // transition: margin-left .28s;
    // margin-left: 220px;
  }
</style>
